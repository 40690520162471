import React from 'react';
import { Image, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { isTomorrow } from 'date-fns';
import { format } from 'date-fns/format';
import { isToday } from 'date-fns/isToday';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { createDate } from '@src/utils/dateHelpers';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { TvChannelShowtime } from '@lib/graphql/generated';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { GrowingElement } from '../GrowingElement/GrowingElement';
import { ShowtimeProgress } from '../ShowtimeProgress/ShowtimeProgress';

export interface TvShowtimeProps extends PropsWithClassProps<'main' | 'title' | 'image' | 'fallback'> {
  showtime: TvChannelShowtime;
  showInitially?: boolean;
  showDate?: boolean;
  favoriteRemovalConfirmation?: boolean;
  lastScroll?: number;
}

export const TvShowtime = ({
  showtime,
  showInitially,
  lastScroll,
  favoriteRemovalConfirmation,
  showDate,
  classProps,
}: TvShowtimeProps) => {
  const channelLogo = getChannelLogo(showtime.channel?.id);

  if (!showtime) return null;

  return (
    <GrowingElement
      showInitially={showInitially}
      lastScroll={lastScroll}
      link={`/programm/${showtime?.event_id}`}
      key={showtime?.id}
      topSlot={
        <>
          {showtime?.image?.url ? (
            <Image
              src={showtime?.image.url}
              alt={showtime?.title}
              fill
              sizes="480px"
              copyright=""
              imageMode="cover"
              classProps={{
                container: twMerge('absolute inset-0 rounded-t-lg overflow-hidden', classProps?.image),
              }}
            />
          ) : (
            <FallbackCard classProps={{ root: 'overflow-hidden rounded-t-lg' }} />
          )}
          {channelLogo && (
            <div className="absolute right-3 top-2 h-5 w-8 rounded bg-gray-820/50 p-1">
              <div className="relative size-full">
                <Image
                  src={channelLogo}
                  alt={showtime.channel?.name}
                  priority={true}
                  placeholder="empty"
                  width={48}
                  height={24}
                  copyright=""
                  classProps={{ root: 'h-auto max-h-full w-12 object-fit', container: 'flex items-center' }}
                />
              </div>
            </div>
          )}
          <ShowtimeProgress showtime={showtime} progressClassProps={{ root: 'absolute bottom-0 inset-x-0 z-10' }} />
        </>
      }
      bottomSlot={
        <>
          <div className="flex w-full justify-between gap-3 p-2 transition-spacings duration-150 canhover:group-hover:px-4 canhover:group-hover:pt-3">
            <div className="mt-1">
              {showtime?.start && showtime?.stop && (
                <div className="text-sm text-gray-400">
                  {showDate &&
                    (isToday(createDate(showtime?.start))
                      ? 'Heute'
                      : isTomorrow(createDate(showtime?.start))
                        ? 'Morgen'
                        : format(createDate(showtime?.start), 'dd.MM')) + ', '}
                  {format(createDate(showtime?.start), 'HH:mm')}&nbsp;-&nbsp;
                  {format(createDate(showtime?.stop), 'HH:mm')} Uhr
                </div>
              )}
              <div
                className={twMerge(
                  'hyphenate line-clamp-2 font-herokid text-sm font-medium text-gray-900 dark:text-white md:text-base canhover:group-hover:line-clamp-1 canhover:group-hover:text-white',
                  classProps?.title,
                )}
              >
                {showtime?.title}
              </div>
            </div>
            <FavoriteButton
              id={showtime.event_id}
              type="TVChannelShowtime"
              askBeforeRemove={favoriteRemovalConfirmation}
              classProps={{ root: 'mt-2' }}
            />
          </div>
          <div className="mt-2 grid h-0 w-full grid-rows-[1fr,auto] overflow-hidden px-4 canhover:sm:group-hover:h-28 canhover:sm:group-hover:pb-4">
            <div className="mt-1 flex flex-wrap content-start items-start gap-1">
              {showtime.genre && <Genre genre={{ title: showtime.genre, id: '01' }} />}
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm text-white">
              <span className="font-bold">{showtime.year}</span>
            </div>
          </div>
        </>
      }
    />
  );
};
