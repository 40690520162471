import React, { Fragment } from 'react';
import Head from 'next/head';
import { Transition } from '@headlessui/react';
import { isAfter } from 'date-fns';
import { useSession } from 'next-auth/react';
import { PageBody } from 'pages/[categoryOrPage]';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Button } from '@components/atoms/Button/Button';
import { LoadingIndicator } from '@components/atoms/LoadingIndicator/LoadingIndicator';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { StreamingTeaser } from '@components/molecules/StreamingTeaser/StreamingTeaser';
import { TvShowtime } from '@components/molecules/TvShowtime/TvShowtime';
import { useCurrentTime } from '@src/lib/hooks/useCurrentTime';
import { useFavoritesStore } from '@src/stores/favorites';
import { adPositions } from '@lib/adition/adPositions';
import {
  GetBySlugFullDocument,
  Movie,
  Show,
  TvChannelShowtime,
  TvmFavoritesFullDocument,
  TvmFavoritesShowtimesDocument,
  TvmFavoriteType,
} from '@lib/graphql/generated';
import { createUrqlClient, usePaginatedAuthQuery } from '@lib/graphql/urql';

const FallbackMessage = ({ text = 'Keine Inhalte vorhanden.', emoji }) => (
  <div className="bg-gray-900/30 p-8">
    <div className="grid max-w-screen-xs items-center justify-center justify-items-center gap-6 text-balance rounded bg-gradient-to-br from-gray-800/50 to-gray-800/20 px-12 py-16 text-center text-sm font-semibold text-gray-400 md:text-base">
      <div className="inline-block text-4xl">{emoji}</div>
      <div className="mx-auto max-w-64 leading-tight">{text}</div>
    </div>
  </div>
);

export function WatchlistPage({ data }) {
  const { metadata, pageContent } = data;
  const { status } = useSession();

  const { currentTime } = useCurrentTime();
  const { getFavoriteState } = useFavoritesStore((state) => ({
    getFavoriteState: state.getFavoriteState,
  }));

  const [
    {
      data: showtimesData,
      fetching: showtimesLoading,
      fetchNextPage: fetchMoreShowtimes,
      hasMorePages: hasMoreShowtimes,
    },
  ] = usePaginatedAuthQuery({
    query: TvmFavoritesShowtimesDocument,
    variables: {
      orderBy: 'start',
      orderDirection: 'asc',
      first: 10,
      page: 1,
    },
  });

  const [
    { data: moviesData, fetching: movieFavoritesLoading, fetchNextPage: fetchMoreMovies, hasMorePages: hasMoreMovies },
  ] = usePaginatedAuthQuery({
    query: TvmFavoritesFullDocument,
    variables: {
      includeTypes: [TvmFavoriteType.Movie],
      first: 10,
      page: 1,
    },
  });

  const [
    { data: showsData, fetching: showFavoritesLoading, fetchNextPage: fetchMoreShows, hasMorePages: hasMoreShows },
  ] = usePaginatedAuthQuery({
    query: TvmFavoritesFullDocument,
    variables: {
      includeTypes: [TvmFavoriteType.Tvshow],
      first: 10,
      page: 1,
    },
  });

  const showtimes = showtimesData
    ?.flatMap((e) => e?.tvmFavoritesShowtimes.data)
    ?.filter(Boolean)
    .filter((e) => isAfter(e.stop, currentTime))
    .filter((e) => getFavoriteState(e.__typename === 'TVChannelShowtime' && e.event_id));

  const movieFavorites = moviesData
    .flatMap((e) => e.tvmFavorites.data)
    ?.map((e) => e.entity)
    ?.filter(Boolean)
    .filter((e) => getFavoriteState(e.id));

  const showFavorites = showsData
    .flatMap((e) => e.tvmFavorites.data)
    ?.map((e) => e.entity)
    ?.filter(Boolean)
    .filter((e) => getFavoriteState(e.id));

  return (
    <div className="px-4 sm:px-8 md:px-16 lg:mt-16">
      <Oewa pageKey={'Watchlist'} categoryKey={'Sonstiges'} profileKey={'Sonstiges'} />
      <Head>{Meta(metadata)}</Head>
      <AditionInitialization tags={adPositions} data={data} />
      {pageContent && <PageBody pageContent={pageContent} />}
      {status === 'unauthenticated' ? (
        <div className="mx-auto max-w-screen-md rounded-lg bg-gray-700 p-16 text-center">
          <Title level={2} classProps={{ heading: 'pb-4 dark:text-white' }}>
            Watchlist
          </Title>
          <div>Jetzt einloggen und personalisierte Inhalte hinzufügen.</div>
          <Button
            title="Zum Login"
            link="/auth/login?callbackUrl=/watchlist"
            buttonStyle="primary"
            classProps={{ root: 'mt-6' }}
          />
        </div>
      ) : (
        <div className="space-y-16">
          <section>
            <Title level={2} classProps={{ heading: 'pb-8 dark:text-white' }}>
              TV-Programm
            </Title>
            {showtimesLoading || showtimes?.length > 0 ? (
              <>
                <div className="grid grid-flow-dense grid-cols-fill-streaming-default gap-x-4 gap-y-6">
                  {showtimes?.map((entity) => (
                    <Fragment key={entity?.id}>
                      <Transition
                        as="div"
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={true}
                        appear={true}
                      >
                        <TvShowtime showtime={entity as TvChannelShowtime} showDate favoriteRemovalConfirmation />
                      </Transition>
                    </Fragment>
                  ))}
                </div>
                {showtimesLoading ? (
                  <LoadingIndicator visible classProps={{ root: 'w-full max-w-md h-96' }} />
                ) : (
                  hasMoreShowtimes && (
                    <div className="text-center">
                      <Button
                        title="Mehr laden"
                        hasAction
                        onAction={() => setTimeout(fetchMoreShowtimes)}
                        classProps={{ root: 'mt-20' }}
                        isLoading={showtimesLoading}
                        buttonStyle="secondary"
                      />
                    </div>
                  )
                )}
              </>
            ) : (
              <FallbackMessage
                emoji="🍿"
                text="Derzeit gibt es keine favorisierten TV Sendungen."
                key="no-showtime-favorites"
              />
            )}
          </section>
          <section>
            <Title level={2} classProps={{ heading: 'pb-8 dark:text-white' }}>
              Film
            </Title>
            {movieFavoritesLoading || movieFavorites?.length > 0 ? (
              <>
                <div className="grid grid-flow-dense grid-cols-fill-streaming-default gap-x-4 gap-y-12">
                  {movieFavorites?.map((entity) => (
                    <Fragment key={entity.id}>
                      <Transition
                        as="div"
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={true}
                        appear={true}
                      >
                        <StreamingTeaser
                          entry={entity as Movie | Show}
                          listicleType={entity?.__typename?.toUpperCase() as 'MOVIE' | 'SHOW'}
                          favoriteRemovalConfirmation
                        />
                      </Transition>
                    </Fragment>
                  ))}
                </div>
                {movieFavoritesLoading ? (
                  <LoadingIndicator visible classProps={{ root: 'w-full max-w-md h-96' }} />
                ) : (
                  hasMoreMovies && (
                    <div className="text-center">
                      <Button
                        title="Mehr laden"
                        hasAction
                        onAction={() => setTimeout(fetchMoreMovies)}
                        classProps={{ root: 'mt-20' }}
                        isLoading={movieFavoritesLoading}
                        buttonStyle="secondary"
                      />
                    </div>
                  )
                )}
              </>
            ) : (
              <FallbackMessage
                emoji="🎬"
                text="Deine Film-Favoriten werden hier aufscheinen."
                key="no-movie-favorites"
              />
            )}
          </section>
          <section>
            <Title level={2} classProps={{ heading: 'pb-8 dark:text-white' }}>
              Serien
            </Title>
            {showFavoritesLoading || showFavorites?.length > 0 ? (
              <>
                <div className="grid grid-flow-dense grid-cols-fill-streaming-default gap-x-4 gap-y-12">
                  {showFavorites?.map((entity) => (
                    <Fragment key={entity.id}>
                      <Transition
                        as="div"
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={true}
                        appear={true}
                      >
                        <StreamingTeaser
                          entry={entity as Movie | Show}
                          listicleType={entity?.__typename?.toUpperCase() as 'MOVIE' | 'SHOW'}
                          favoriteRemovalConfirmation
                        />
                      </Transition>
                    </Fragment>
                  ))}
                </div>
                {showFavoritesLoading ? (
                  <LoadingIndicator visible classProps={{ root: 'w-full max-w-md h-80' }} />
                ) : (
                  hasMoreShows && (
                    <div className="text-center">
                      <Button
                        title="Mehr laden"
                        hasAction
                        onAction={() => setTimeout(fetchMoreShows)}
                        classProps={{ root: 'mt-20' }}
                        isLoading={showFavoritesLoading}
                        buttonStyle="secondary"
                      />
                    </div>
                  )
                )}
              </>
            ) : (
              <FallbackMessage
                emoji="📽️"
                text="Deine Serien-Favoriten werden hier aufscheinen."
                key="no-tvshow-favorites"
              />
            )}
          </section>
        </div>
      )}
    </div>
  );
}

export const watchlistSsrLogic = async () => {
  const { ssrQuery, ssrCache } = createUrqlClient();

  const { data } = await ssrQuery({
    query: GetBySlugFullDocument,
    variables: {
      portal: 'tvmedia',
      slug: '/watchlist',
    },
  });

  return {
    revalidate: 60 * 60 * 24 * 2, // 2 days
    props: {
      data: data?.findBySlug,
      slug: 'watchlist',
      urqlState: ssrCache?.extractData() || null,
    },
  };
};
